<footer>
  <div class="container">
    <div class="row" id="row__footer">
      <div class="footer">
        <div class="footer__description">
          <h4 class="footer__description--title">William Mendoza</h4>
          <p class="footer__description--info">Desarrollador de software</p>
        </div>
        <div class="footer__links">
          <p class="footer__links--title">Links</p>
          <div class="footer__links--enlaces">
            <div class="links__enlace">
              <ul>
                <li>Home</li>
                <li>About me</li>
                <li>Services</li>
              </ul>
            </div>
            <div class="links__enlace">
              <ul>
                <li>Portfolio</li>
                <li>Blog</li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer__social">
          <p class="footer__social--title">Sigueme en mis redes</p>
          <p class="footer__social--paragrah">
            Puedes encontrar aun mas contenido en ellas asi que no pierdas la
            oportunidad de seguirme
          </p>
          <div class="footer__social--redes">
            <a href="" class="social-icon">
              <img
                src="../../../assets/img/iconLinkedin.svg"
                alt="linkedin icon"
                class="social-icon__img"
              />
            </a>
            <a href="" class="social-icon">
              <img
                src="../../../assets/img/iconTwitter.svg"
                alt="twiiter icon"
                class="social-icon__img"
              />
            </a>
            <a href="" class="social-icon">
              <img
                src="../../../assets/img/Iconinstagram.svg"
                alt="instagram icon"
                class="social-icon__img"
              />
            </a>
            <a href="" class="social-icon">
              <img
                src="../../../assets/img/iconGithub.svg"
                alt="github icon"
                class="social-icon__img"
              />
            </a>
            <a href="" class="social-icon">
              <img
                src="../../../assets/img/iconGitlab.svg"
                alt="gitlab icon"
                class="social-icon__img"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
