<section>
  <div class="container">
    <div class="row">
      <div class="cta">
        <h3 class="cta--h3">Danos el placer de trabajar a tu lado.</h3>
        <p class="cta--paragraph">
          Se parte de nuestra familia y juntos transformemos a este mundo en un
          lugar mas idoneo para toda nuestra decendencia, se parte de nuestros
          aliados en este camino de transformación.
        </p>
        <div class="cta--btn">
          <button class="">Contact Me</button>
        </div>
      </div>
    </div>
  </div>
</section>
