<div class="d-flex" id="wrapper">
  <app-sidebar *ngIf="toggleMenu"></app-sidebar>
  <!-- Page content wrapper-->
  <div id="page-content-wrapper">
    <app-header (toggleMenu)="getValueMenu($event)"></app-header>
    <!-- Page content-->
    <div class="container-fluid">
      <app-title></app-title>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
