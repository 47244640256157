import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuValue = true;
  @Output() toggleMenu = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggledMenu() {
    if (this.menuValue === undefined) {
      this.menuValue = true;
    }
    this.menuValue = !this.menuValue;
    this.toggleMenu.emit(this.menuValue);
  }
}
