<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid p-3">
    <a
      class="navbar-brand nav-link mx-5"
      routerLink="/home"
      routerLinkActive="router-link-active"
      ><h5>William Mendoza</h5>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/home"
            routerLinkActive="router-link-active"
            ><h5>Home</h5></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/portfolio"
            routerLinkActive="router-link-active"
            ><h5>Portfolio</h5></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/blog"
            routerLinkActive="router-link-active"
            ><h5>Blog</h5></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/contact"
            routerLinkActive="router-link-active"
            ><h5>Contact</h5></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/login"
            routerLinkActive="router-link-active"
            ><i class="fas fa-user-lock"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
