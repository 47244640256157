import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'portfolio',
        loadChildren: () =>
          import('./porfolio/porfolio.module').then((m) => m.PorfolioModule),
      },
      {
        path: 'portfolio/categoria',
        loadChildren: () =>
          import('./detalles-categoria/detalles-categoria.module').then(
            (m) => m.DetallesCategoriaModule
          ),
      },
      {
        path: 'portfolio/categoria/proyecto',
        loadChildren: () =>
          import('./detailproject/detailproject.module').then(
            (m) => m.DetailprojectModule
          ),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
