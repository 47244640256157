<!-- Sidebar-->
<div class="border-end bg-white" id="sidebar-wrapper">
  <div class="sidebar-heading border-bottom bg-light">William Mendoza</div>
  <div class="list-group list-group-flush">
    <a
      class="list-group-item list-group-item-action list-group-item-light p-3"
      [routerLink]="['/admin']"
      routerLinkActive="router-link-active"
    >
      <i class="fas fa-columns"></i> Dashboard</a
    >
    <a
      class="list-group-item list-group-item-action list-group-item-light p-3"
      [routerLink]="['/admin/certification']"
      routerLinkActive="router-link-active"
    >
      <i class="fas fa-briefcase"></i> Certificaciones</a
    >
    <a
      class="list-group-item list-group-item-action list-group-item-light p-3"
      [routerLink]="['/admin/categorias']"
      routerLinkActive="router-link-active"
    >
      <i class="fas fa-briefcase"></i> Categorias</a
    >
    <a
      class="list-group-item list-group-item-action list-group-item-light p-3"
      [routerLink]="['/admin/projects']"
      routerLinkActive="router-link-active"
    >
      <i class="fas fa-tools"></i> Proyectos</a
    >
  </div>
</div>
